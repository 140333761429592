/*
* The syntax highlight.
*/

@import "colors/light-syntax";
@import "colors/dark-syntax";
html {
    @media (prefers-color-scheme: light) {
        &:not([data-mode]), &[data-mode=light] {
            @include light-syntax;
        }
        &[data-mode=dark] {
            @include dark-syntax;
        }
    }
    @media (prefers-color-scheme: dark) {
        &:not([data-mode]),
        &[data-mode=dark] {
            @include dark-syntax;
        }
        &[data-mode=light] {
            @include light-syntax;
        }
    }
}


/* -- Codes Snippet -- */

$code-radius: 6px;
%code-snippet-bg {
    background: var(--highlight-bg-color);
}

%code-snippet-radius {
    border-radius: $code-radius;
}

%code-snippet-padding {
    padding-left: 1rem;
    padding-right: 1.5rem;
}

.highlighter-rouge {
    @extend %code-snippet-bg;
    @extend %code-snippet-radius;
    color: var(--highlighter-rouge-color);
    margin-top: 0.5rem;
    margin-bottom: 1.2em;
    /* Override BS Inline-code style */
}

.highlight {
    @extend %code-snippet-radius;
    @extend %code-snippet-bg;
    @at-root figure#{&} {
        @extend %code-snippet-bg;
    }
    overflow: auto;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    pre {
        margin-bottom: 0;
        font-size: $code-font-size;
        line-height: 1.4rem;
        word-wrap: normal;
        /* Fixed Safari overflow-x */
    }
    table {
        td pre {
            overflow: visible;
            /* Fixed iOS safari overflow-x */
            word-break: normal;
            /* Fixed iOS safari linenos code break */
        }
    }
    .lineno {
        padding-right: 0.5rem;
        min-width: 2.2rem;
        text-align: right;
        color: var(--highlight-lineno-color);
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    }
    /* set the dollar sign to non-selectable */
    .gp {
        user-select: none;
    }
}


/* .highlight */

code {
    -webkit-hyphens: none;
    -ms-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
    &.highlighter-rouge {
        font-size: $code-font-size;
        padding: 3px 5px;
        border-radius: 4px;
        background-color: var(--inline-code-bg);
    }
    &.filepath {
        background-color: inherit;
        color: var(--filepath-text-color);
        font-weight: 600;
        padding: 0;
    }
    a>&.highlighter-rouge {
        padding-bottom: 0;
        /* show link's underlinke */
        color: inherit;
    }
    a:hover>&.highlighter-rouge {
        border-bottom: none;
    }
    blockquote & {
        color: inherit;
    }
    .highlight>& {
        color: transparent;
    }
}

td.rouge-code {
    @extend %code-snippet-padding;
    /*
    Prevent some browser extends from
    changing the URL string of code block.
  */
    a {
        color: inherit !important;
        border-bottom: none !important;
        pointer-events: none;
    }
}


/* Hide line numbers for default, console, and terminal code snippets */

div {
    &[class^='highlighter-rouge'],
    &.language-plaintext.highlighter-rouge,
    &.language-console.highlighter-rouge,
    &.language-terminal.highlighter-rouge,
    &.nolineno {
        pre.lineno {
            display: none;
        }
        td.rouge-code {
            padding-left: 1.5rem;
        }
    }
}

.code-header {
    @extend %no-cursor;
    $code-header-height: 2.25rem;
    border-top-left-radius: $code-radius;
    border-top-right-radius: $code-radius;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $code-header-height;
    &::before {
        $dot-size: 0.75rem;
        $dot-margin: 0.5rem;
        content: "";
        display: inline-block;
        margin-left: 1rem;
        width: $dot-size;
        height: $dot-size;
        border-radius: 50%;
        background-color: var(--code-header-muted-color);
        box-shadow: ($dot-size + $dot-margin) 0 0 var(--code-header-muted-color), ($dot-size + $dot-margin) * 2 0 0 var(--code-header-muted-color);
    }
    /* the label block */
    span {
        /* label icon */
        i {
            font-size: 1rem;
            margin-right: 0.4rem;
            color: var(--code-header-icon-color);
            &.small {
                font-size: 70%;
            }
        }
        @at-root [file] #{&}>i {
            position: relative;
            top: 1px;
            /* center the file icon */
        }
        /* label text */
        &::after {
            content: attr(data-label-text);
            font-size: 0.85rem;
            font-weight: 600;
            color: var(--code-header-text-color);
        }
    }
    /* clipboard */
    button {
        @extend %cursor-pointer;
        border: 1px solid transparent;
        border-radius: $code-radius;
        height: $code-header-height;
        width: $code-header-height;
        padding: 0;
        background-color: inherit;
        i {
            color: var(--code-header-icon-color);
        }
        &[timeout] {
            &:hover {
                border-color: var(--clipboard-checked-color);
            }
            i {
                color: var(--clipboard-checked-color);
            }
        }
        &:not([timeout]):hover {
            background-color: rgba(128, 128, 128, 0.37);
            i {
                color: white;
            }
        }
        &:focus {
            outline: none;
        }
    }
}

@media all and (max-width: 576px) {
    .post-content {
        >div[class^='language-'] {
            @include ml-mr(-1.25rem);
            border-radius: 0;
            .highlight {
                padding-left: 0.25rem;
            }
            .code-header {
                border-radius: 0;
                padding-left: 0.4rem;
                padding-right: 0.5rem;
            }
        }
    }
}
